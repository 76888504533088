import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.scss';
import Spacer from '../components/spacer';
import Header from '../components/header';
import { graphql} from "gatsby";
import Headline from '../components/headline';
import Lilschk from '../components/lilschk';
import Gallery from '../components/gallery';
import { motion } from "framer-motion";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';



function Index({data}) {
  
    return (
      <ParallaxProvider>
        <main>
          <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1}}
          exit={{ opacity: 0}}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
          >
            <Helmet>
              <title>Lilschk Photography - People Portfolio</title>
            </Helmet>
            <div className="fixed foreground">
              <Header></Header>
              <Lilschk></Lilschk>
            </div>
            <Headline title={"people"}></Headline>
            <Spacer height={"400"}></Spacer>
            <Gallery data={data}></Gallery>
            </motion.main>
        </main>
      </ParallaxProvider>
    )
}

export default Index;



export const query = graphql`
    query {
      lifestyle: allFile( filter: { absolutePath: {regex: "./images/portraits/" } }) {
          edges {
            node {
              childImageSharp {
                  fluid (maxWidth: 600){
                      src
                      srcSet
                      aspectRatio
                      sizes
                      base64
                  }
            }
          }
          }
          }




    }`
